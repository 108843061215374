import React, { Component } from "react"
import styled from "styled-components"

import Emoji from "@styles/Emoji"

import WaveEmojiIcon from "@images/emojis/wave.png"

const StyledContainer = styled(Emoji)`
  display: inline-block;
  vertical-align: text-top;
  background-image: url(${WaveEmojiIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
  cursor: pointer;

  &.animated {
    animation-delay: 1s;
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: wave;
  }

  &.hovering {
    animation-delay: 0s;
  }

  @keyframes wave {
    from {
      transform: none;
    }
    15% {
      transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, -10deg);
    }
    30% {
      transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 7deg);
    }
    45% {
      transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
    }
    60% {
      transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 5deg);
    }
    75% {
      transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -2deg);
    }
    to {
      transform: none;
    }
  }
`

class WaveHand extends Component {
  state = { isWaving: true, isHovering: false }

  onMouseEnter = () => {
    this.setState(() => ({
      isWaving: false,
      isHovering: false,
    }))
    // Restart animation
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        this.setState(() => ({ isWaving: true, isHovering: true }))
      })
    })
  }

  render() {
    return (
      <StyledContainer
        className={
          "wave-hand " +
          (this.state.isWaving ? " animated" : "") +
          (this.state.isHovering ? " hovering" : "")
        }
        style={{ width: "40px", height: "40px" }}
        onMouseEnter={this.onMouseEnter}
      ></StyledContainer>
    )
  }
}

export default WaveHand
