import styled from "styled-components"

const Emoji = styled.span`
  width: 40px;
  height: 40px;
  display: inline-block;
  vertical-align: text-top;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.emoji});
`

export default Emoji
