import React from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import Section from "@styles/Section"
import UnderlineLink from "@styles/UnderlineLink"

import cat from "@images/cat.jpeg"

const images = [{ source: cat, caption: "Minette" }]

class Background extends React.Component {
  state = { modalIsOpen: false }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }))
  }

  render() {
    const { modalIsOpen } = this.state

    return (
      <>
        <Section>
          <Section.Title>Background</Section.Title>
          <Section.Content>
            <p>
              I'm currently a research engineer at{" "}
              <UnderlineLink
                as={OutboundLink}
                href="https://www.eurecom.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                EURECOM
              </UnderlineLink>
              , building things for the web with some awesome people. I
              graduated from{" "}
              <UnderlineLink
                as={OutboundLink}
                href="http://www.polytech.unice.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Polytech Nice Sophia
              </UnderlineLink>{" "}
              with a Master's degree in computer science.
            </p>

            <p>
              <strong>As a software engineer</strong>, I always enjoy taking on
              new challenges and combining my technical knowledge and creativity
              to solve problems in the most efficient and elegant way possible.
              My goal is to always build applications that are scalable and
              reliable while providing engaging user experiences.
            </p>

            <p>
              <strong>When I'm not in front of a computer screen</strong>, I'm
              probably at the gym, riding my bike, watching movies, or crossing
              off another item on my bucket list.
            </p>

            <p>
              Since we're on the Internet, here is the mandatory{" "}
              <UnderlineLink
                as={OutboundLink}
                href={cat}
                target="_blank"
                rel="noopener noreferrer"
                onClick={e => {
                  e.preventDefault()
                  this.toggleModal()
                }}
              >
                cat tax
              </UnderlineLink>{" "}
              (she's {new Date().getFullYear() - 2017} now!).
            </p>
          </Section.Content>
        </Section>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel views={images} />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    )
  }
}

export default Background
