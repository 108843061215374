import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import { breakpoints, mixins } from "@styles"
import Section from "@styles/Section"
import UnderlineLink from "@styles/UnderlineLink"

import ArrowIcon from "@images/arrow.png"
import FrenchResume from "@files/CV_Thibault_Ehrhart.pdf"

const StyledJobs = styled.div`
  margin-bottom: 100px;
`

const StyledJob = styled.div`
  margin-bottom: 25px;
`

const StyledTimePlace = styled.div`
  display: flex;
  justify-content: space-between;
  ${breakpoints.mobile`display: block;`}
`

const StyledCompany = styled.div`
  font-weight: 700;
`

const StyledTime = styled.div`
  font-family: "Inconsolata", monospace;
  font-size: 0.9rem;
  ${breakpoints.mobile`font-size: 0.8rem;`}
`

const StyledJobPosition = styled.div``

const StyledArrowLink = styled.a`
  font-size: 1.25rem;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  &:after {
    width: 20px;
    height: 20px;
    ${mixins.transition("transform")}
    font-weight: 700;
    display: inline-block;
    white-space: nowrap;
    position: relative;
    bottom: 2px;
    margin-left: 10px;
    content: "";
    background: url(${ArrowIcon}) no-repeat center transparent;
    background-size: 100%;
    vertical-align: middle;
  }
  &:hover:after,
  &:focus:after {
    transform: translateX(5px);
  }
`

const Experience = () => {
  const data = useStaticQuery(graphql`
    query {
      allJobsYaml {
        edges {
          node {
            url
            company
            time
            position
          }
        }
      }
    }
  `)

  return (
    <Section>
      <Section.Title>Experience</Section.Title>
      <Section.Content>
        <StyledJobs>
          {data.allJobsYaml.edges.map((job, index) => (
            <StyledJob key={index}>
              <StyledTimePlace>
                <StyledCompany>
                  <UnderlineLink
                    as={OutboundLink}
                    href={job.node.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {job.node.company}
                  </UnderlineLink>
                </StyledCompany>
                <StyledTime>{job.node.time}</StyledTime>
              </StyledTimePlace>
              <StyledJobPosition>{job.node.position}</StyledJobPosition>
            </StyledJob>
          ))}
        </StyledJobs>
        <StyledArrowLink
          as={OutboundLink}
          href={FrenchResume}
          target="_blank"
          rel="noopener noreferrer"
        >
          View My Resume
        </StyledArrowLink>
      </Section.Content>
    </Section>
  )
}

export default Experience
