import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"
import Intro from "@components/intro"
import Background from "@components/background"
import Skills from "@components/skills"
import Experience from "@components/experience"
import Switch from "@components/switch"
import Footer from "@components/footer"
import TopButton from "@components/topbutton"

const IndexPage = () => (
  <Layout>
    <Switch />
    <SEO />
    <Intro />
    <Background />
    <Skills />
    <Experience />
    <Footer />
    <TopButton />
  </Layout>
)

export default IndexPage
