import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import UnderlineLink from "@styles/UnderlineLink"
import Emoji from "@styles/Emoji"

import RockonEmoji from "@images/emojis/rockon.png"

import { breakpoints } from "@styles"

const StyledFooter = styled.footer`
  padding: 50px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${breakpoints.desktop`padding: 50px;`}
  ${breakpoints.weirdNotTablet`display: block;`}
  ${breakpoints.mobile`padding: 50px 30px;`}
`

const FooterLinks = styled.footer`
  ${breakpoints.weirdNotTablet`display: flex; justify-content: center;`}

  ${UnderlineLink} {
    color: var(--blue);
    text-transform: uppercase;
    font-weight: 700;
    margin: 0 15px;
    outline: none;
  }
`

const StyledCopyright = styled.div`
  width: 200px;
  ${breakpoints.weirdNotTablet`
    width: auto;
    text-align: center;
    margin-bottom: 25px;
  `}

  ${Emoji} {
    width: 20px;
    height: 20px;
    margin: 0 3px;
    vertical-align: text-bottom;
  }
`

const StyledTop = styled.div``

const StyledBottom = styled.div``

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      allLinksYaml {
        edges {
          node {
            url
            title
          }
        }
      }
    }
  `)

  return (
    <StyledFooter>
      <StyledCopyright>
        <StyledTop></StyledTop>
        <StyledBottom>
          <span>Thibault Ehrhart</span>
          <Emoji emoji={RockonEmoji} />
          <span>2020</span>
        </StyledBottom>
      </StyledCopyright>
      <FooterLinks>
        {data.allLinksYaml.edges.map((link, index) => (
          <UnderlineLink
            key={index}
            as={OutboundLink}
            href={link.node.url}
            target="_blank"
            rel="noopener noreferrer"
            title={link.node.title}
          >
            {link.node.title}
          </UnderlineLink>
        ))}
      </FooterLinks>
    </StyledFooter>
  )
}

export default Footer
