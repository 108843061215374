import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import { breakpoints } from "@styles"
import Section from "@styles/Section"

const StyledList = styled.div`
  display: flex;
  justify-content: space-between;
  ${breakpoints.weirdNotTablet`flex-wrap: wrap;`}
`

const StyledCategory = styled.div`
  ${breakpoints.weirdNotTablet`
    width: 47%;
    margin-right: 5px;
    margin-bottom: 25px;
  `}
`

const StyledLabel = styled.div`
  font-weight: 700;
  color: inherit;
  text-transform: uppercase;
`

const StyledItem = styled.li`
  margin: 3px 0;
  font-size: 0.9rem;
`

const Skills = () => {
  const data = useStaticQuery(graphql`
    query {
      allSkillsYaml {
        edges {
          node {
            category
            items
          }
        }
      }
    }
  `)

  return (
    <Section>
      <Section.Title>Skills</Section.Title>
      <Section.Content>
        <StyledList>
          {data.allSkillsYaml.edges.map((skill, index) => (
            <StyledCategory key={index}>
              <StyledLabel>{skill.node.category}</StyledLabel>
              <ul>
                {skill.node.items.map((item, itemIndex) => (
                  <StyledItem key={itemIndex}>{item}</StyledItem>
                ))}
              </ul>
            </StyledCategory>
          ))}
        </StyledList>
      </Section.Content>
    </Section>
  )
}

export default Skills
