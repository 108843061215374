import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"

import WaveHand from "@components/wavehand"
import Emoji from "@styles/Emoji"
import UnderlineLink from "@styles/UnderlineLink"
import { breakpoints, mixins } from "@styles"

import TechnologistEmojiIcon from "@images/emojis/technologist.png"
import PointerEmojiIcon from "@images/emojis/pointright.png"

const StyledContainer = styled.header`
  padding: 120px 100px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 1440px;
  margin: 0 auto;

  ${breakpoints.weirdNotTablet`padding: 120px 70px;`}
  ${breakpoints.phablet`padding: 120px 50px;`}
  ${breakpoints.mobile`padding 70px 50px;`}
`

const StyledTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 100%;

  ${breakpoints.desktop`font-size: 2.25rem;`}
  ${breakpoints.tablet`font-size: 2rem;`}
  ${breakpoints.phablet`font-size: 1.75rem;`}
  ${breakpoints.smallMobile`font-size: 1.5rem;`}
  ${breakpoints.tinyMobile`font-size: 1.4rem;`}
`

const StyledTagline = styled.h2`
  font-size: 2.5rem;
  font-weight: 300;
  max-width: 800px;

  ${breakpoints.desktop`font-size: 2.25rem;`}
  ${breakpoints.tablet`font-size: 2rem;`}
  ${breakpoints.phablet`font-size: 1.75rem;`}
  ${breakpoints.smallMobile`font-size: 1.5rem;`}
  ${breakpoints.tinyMobile`font-size: 1.4rem;`}
`

const StyledContact = styled.h3`
  font-size: 1.25rem;
  font-weight: 400;

  ${breakpoints.phablet`font-size: 1rem;`}

  ${Emoji} {
    width: 30px;
    height: 30px;
    margin: 0 5px;
    vertical-align: text-bottom;
    ${breakpoints.phablet`
      width: 25px;
      height: 25px;
    `}
  }
`

const StyledName = styled.span`
  font-weight: 700;
`

const StyledHighlightLink = styled.a`
  ${mixins.transition("box-shadow, color")}
  font-weight: 400;
  display: inline-block;
  padding: 2px 3px;
  text-decoration: none;
  box-shadow: inset 0 -3px 0 var(--blue);
  &:hover,
  &:focus {
    box-shadow: inset 0 -33px 0 0 var(--blue);
    color: var(--white);
  }
`

const Intro = () => (
  <StyledContainer>
    <StyledTitle>
      Hello! <WaveHand />
    </StyledTitle>

    <StyledTagline>
      I'm{" "}
      <UnderlineLink as={Link} to="/">
        <StyledName>Thibault Ehrhart</StyledName>
      </UnderlineLink>
      , a research engineer and{" "}
      <UnderlineLink
        as={OutboundLink}
        href="https://github.com/ehrhart"
        target="_blank"
        rel="noopener noreferrer"
      >
        developer
      </UnderlineLink>{" "}
      living in France, currently working for{" "}
      <UnderlineLink
        as={OutboundLink}
        href="https://www.eurecom.fr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        EURECOM
      </UnderlineLink>
      .<Emoji emoji={TechnologistEmojiIcon}></Emoji>
    </StyledTagline>

    <StyledContact>
      <span>Get in touch </span>
      <Emoji emoji={PointerEmojiIcon}></Emoji>
      <span>
        <StyledHighlightLink
          href="mailto:thibault.ehrhart@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          thibault.ehrhart@gmail.com
        </StyledHighlightLink>
      </span>
    </StyledContact>
  </StyledContainer>
)

export default Intro
